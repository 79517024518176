// Set background images
$(document).ready(function() {
    //Click event to scroll to top
    $('.scrollToTop').click(function(){
        $('html, body').animate({scrollTop : 0},800);
        return false;
    });

    fontsize();
    initFontSize();

});

$(document).ajaxStop(function() {
  var homeClass = $("body").attr("class");

  if(homeClass != "home"){
    $("nav.top-bar-section .lvl1").prepend("<li><a href='/homepage'>Home</a></li>");
    $("#mobileNav .lvl1").prepend("<li><a href='/homepage'>Home</a></li>");
  };
});


// functions
function fontsize() {
    var fonts = $("<div id='fontSize'><span class='js-font' data-size='small' style='font-size:12px;'>a</span><span class='js-font' data-size='regular' style='font-size:16px;'>a</span><span class='js-font' data-size='large' style='font-size:32px;'>a</span></div>");
    $(fonts).prependTo(".top-bar");

    $('.js-font').on('click', function() {
        event.preventDefault();
        $('body').attr('class', '').addClass($(this).data('size'));
        // re-calculate height of blocks
        $(document).foundation('equalizer','reflow');

        // set setting in localstorage, and apply changes directly without refresh
        var current = $('body').attr('class');
        localStorage.setItem("fontSize", current);
    });
}

function initFontSize() {
    switch(localStorage.fontSize) {
        case "small":
            console.log('initfontsize: small font chosen');
            $("body").addClass("small");
            break;

        case "regular":
            console.log('initfontsize: regular font chosen');
            $("body").addClass("regular");
            break;

        case "large":
            console.log('initfontsize: large font chosen');
            $("body").addClass("large");
            break;
    }
}